.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

.margin {
  margin-top: 2.5rem;
}