.app {
  display: flex;
  flex-direction: column;
}

.app > h2 {
  background-color: var(--midnight-100);
  padding: 8px 16px;
}

.navigation {
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  padding-top: 40px;
  background-color: var(--light);
}

@media (min-width: 62rem) {
  .navigation {
    width: 282px;
  }
}
