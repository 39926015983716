.navGroup {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.navGroup::after,
.navGroup:first-of-type::before {
  content: ' ';
  margin: 1.5rem 0 2rem 1.625rem;
  width: calc(100% - 96px);
}

.items {
  display: flex;
  flex-direction: column;
}
.header {
  margin: 0;
  margin-bottom: 0.75rem;
  padding-left: 2.25rem;
  text-transform: uppercase;
  color: var(--secondary-500);
  font-family: var(--core-xs-400-font-family);
  font-size: var(--core-xs-400-font-size);
  font-weight: var(--core-xs-400-font-weight);
  line-height: var(--core-xs-400-line-height);
}

