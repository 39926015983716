.navItem {
  align-items: center;
  display: flex;
  position: relative;
  text-decoration: none;
  user-select: none;
  padding: 0.75rem 0 0.75rem 2.25rem;
  gap: var(--spacing-200);
  color: var(--text-paragraph);
  font-family: var(--core-s-400-font-family);
  font-size: var(--core-s-400-font-size);
  font-weight: var(--core-s-400-font-weight);
  line-height: var(--core-s-400-line-height);
}

.navItem:hover:not(.disabled) {
  cursor: pointer;
  background-color: var(--background-navigation-hover);
}

.activeNavItem {
  font-weight: var(--core-s-600-font-weight);
}

.activeNavItem.navItem::before {
  position: absolute;
  content: ' ';
  height: 1.625rem;
  left: 0;
  width: 4px;
  background-color: var(--tertiary-500);
}

@media (min-width: 991px) {
  .navItem:focus-visible {
    border-radius: 4px;
    outline: none;
    box-shadow: inset 0 0 0 4px var(--focus);
  }

  .disabledNavItem {
    pointer-events: none;
    cursor: not-allowed;
    color: var(--secondary-400);
  }
}
